import React from "react"
import { useSpring, animated } from "react-spring"

import "./styles.scss"

const Contact = () => {
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(5%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  return (
    <div className="contact">
      <animated.div style={textprops} className="contact-text">
        <h1>Contact</h1>
        <h2>Pour nous contacter veuillez remplir le formulaire ci-dessous.</h2>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          action="/success"
          className="contact-text-form"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>
              Votre nom : <input type="text" name="name" required />
            </label>
          </p>
          <p>
            <label>
              Votre email : <input type="email" name="email" required />
            </label>
          </p>
          <p>
            <label>
              Votre message : <textarea name="message" required></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Envoyer</button>
          </p>
        </form>
      </animated.div>
    </div>
  )
}
export default Contact
