import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="N'hesitez pas à nous contacter pour toute demande d'informations sur la famille Decazes"
        image={socialImage}
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage
